//Function to obtain a parameter value from the URL by parametername
export const getUrlParameter = (paramName, parameters) => {
    // check if sourceURL was given, else use from current url
    if(typeof parameters === 'undefined') {
        parameters = window.location.search;
    }

	paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
    let results = regex.exec(parameters);
    return results === null ? false : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

//Function to add a parameter to given URL
export const addUrlParameter = (paramWithValue, parameters) => {
    //sanitize param that needs to be added
    paramWithValue = paramWithValue.replace("?","");

    if(paramWithValue.length === 0){
        return parameters;
    }

    // make sure added value will be url encoded
    paramWithValue = encodeURI(paramWithValue);

    if((typeof parameters !== 'undefined') && (parameters.length > 0) && (parameters !== "?")) {
        if(parameters.indexOf("?") >= 0) {
           parameters += "&" + paramWithValue;
        } else {
    	   parameters += "?" + paramWithValue;
        }
  	}
    else {
	    parameters = "?" + paramWithValue;
	}

	return parameters;
}

//Function to add/replace a parameter to given URL
export const addOrUpdateUrlParameter = (paramName, paramValue, parameters) => {
    // check if sourceURL was given, else use from current url
    if(typeof parameters === 'undefined') {
        parameters = window.location.search;
    }

    // check if param exists on url, then remove it
    if(getUrlParameter(paramName, parameters)!== null){
       parameters = removeUrlParameter(paramName, parameters);
    }

    // add param
    parameters = addUrlParameter(`${paramName}=${paramValue}`, parameters);

    return parameters;
}

export const removeUrlParameter = (paramName, parameters) => {
    // check if sourceURL was given, else use from current url
    if(typeof parameters === 'undefined') {
        parameters = window.location.search;
    }

    let urlparts = parameters.split('?');

    if ((parameters.length > 0) && (parameters !== "?") ) {
        // remove questionmark on first parameter
        parameters = parameters.replace("?","");

        let prefix = encodeURIComponent(paramName)+'=';
        let pars = parameters.split(/[&;]/g);
        for (let i = pars.length; i-->0;) {
            if (pars[i].lastIndexOf(prefix, 0)!==-1){
                pars.splice(i, 1);
            }   
        }

        // combine all params back again
        parameters = "?" + pars.join('&');
    }

    return (parameters !== "?") ? parameters : '';
}

export const getParametersAsArray = (parameters) => {
 	let returnObj = {};

 	// check if sourceURL was given, else use from current url
    if(typeof parameters === 'undefined') {
        parameters = window.location.search;
    }

    if ((parameters.length > 0) && (parameters !== "?") ) {
	    parameters = parameters.replace("?","");

	 	let jsonParameters = '{"' + parameters.replace(/&/g, '","').replace(/=/g,'":"') + '"}'

		returnObj =	JSON.parse(jsonParameters, 
									function(key, value) { 
										return key===""?value:decodeURIComponent(value) 
									});
	}

    return returnObj;
}

export const capitalizeFirstLetter = (value) => {
    return value[0].toUpperCase() + value.slice(1);
}