export default class Controller {

  constructor(el, options = {}) {

    this.el = el;

    return this;
  }

  static init() {
    // Override this method
  }

  destroy() {
    try {
      this.el.parentNode.removeChild(this.el);
    } catch (err) {
      // could happen if the element does not exist anymore
    }
  }
}